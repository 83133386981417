/**! Contents **/
/**! 1. Reset **/
/**! 2. Helper Classes **/
/**! 3. Typography **/
/**! 4. Position **/
/**! 5. Element Size **/
/**! 6. Images **/
/**! 7. Colors **/
/**! 8. Buttons **/
/**! 9. Icons **/
/**! 10. Lists **/
/**! 11. Lightbox **/
/**! 12. Nav Bar **/
/**! 13. Form Elements **/
/**! 14. Accordions **/
/**! 15. Breadcrumbs **/
/**! 16. Pie Charts **/
/**! 17. Tabs **/
/**! 18. Boxes **/
/**! 19. Sliders **/
/**! 20. Hover Elements **/
/**! 21. Masonry **/
/**! 22. Modals **/
/**! 23. Maps **/
/**! 24. Parallax **/
/**! 25. Notifications **/
/**! 26. Video **/
/**! 27. Image Blocks **/
/**! 28. Mail Chimp & Campaign Monitor **/
/**! 29. Twitter **/
/**! 30. Transitions **/
/**! 31. Spacing **/
/**! 32. Theme Overrides **/
/* Colors */
@font-face {
  font-family: Campton;
  src: url("/Themes/comeos/fonts/campton-light-webfont.eot");
  src: url("/Themes/comeos/fonts/campton-light-webfont.eot?#iefix") format("embedded-opentype"), url("/Themes/comeos/fonts/campton-light-webfont.woff2") format("woff2"), url("/Themes/comeos/fonts/campton-light-webfont.woff") format("woff");
}
@font-face {
  font-family: Campton;
  font-weight: bold;
  src: url("/Themes/comeos/fonts/campton-bold-webfont.eot");
  src: url("/Themes/comeos/fonts/campton-bold-webfont.eot?#iefix") format("embedded-opentype"), url("/Themes/comeos/fonts/campton-bold-webfont.woff2") format("woff2"), url("/Themes/comeos/fonts/campton-bold-webfont.woff") format("woff");
}
@font-face {
  font-family: Campton;
  font-weight: 600;
  src: url("/Themes/comeos/fonts/campton-semibold-webfont.eot");
  src: url("/Themes/comeos/fonts/campton-semibold-webfont.eot?#iefix") format("embedded-opentype"), url("/Themes/comeos/fonts/campton-semibold-webfont.woff2") format("woff2"), url("/Themes/comeos/fonts/campton-semibold-webfont.woff") format("woff");
}
*:focus {
  outline: none !important;
}
.altai-theme-content #bodyView {
  font-size: 100%;
  font-family: 'Campton', 'Lora', 'Helvetica', 'Arial', sans-serif;
  color: #000;
  font-weight: 400;
  /**! 2. Helper Classes **/
  /**! 3. Typography **/
  /*! Typography -- Helpers */
  /**! 4. Position **/
  /**! 6. Images **/
  /**! 7. Colors **/
  /**! 8. Buttons **/
  /*Dropdown styling for searcher*/
  /**! 9. Icons **/
  /**! 12. Nav Bar **/
  /**! 13. Form Elements **/
  /**! 22. Modals **/
  /**! 31. Spacing **/
  /**! 32. Theme Overrides **/
  /*! -- Pillar Typography -- */
  /*! -- Pillar Buttons -- */
  /*! -- Pillar Nav Bar -- */
  /*! -- Pillar Offscreen Nav -- */
  /*! -- Pillar Buttons -- */
  /*! -- Blog post is default class for most templates -- */
  /*! -- Pillar Footers -- */
  /*Comeos Custom*/
  /*****************************************Cards****************************************/
  /*****************************pills for the infocards*********************************/
  /*****************************myComeos*********************************/
  /*****************************like item for the infocards*********************************/
  /*****************************event items for the infocards*********************************/
  /*****************************navbar adjustments*********************************/
  /*header font*/
  /*button row*/
  /*logo top*/
  /*section adjustments*/
  /*buttonrow*/
  /*Table customization*/
  /*Links*/
}
.altai-theme-content #bodyView h1,
.altai-theme-content #bodyView h2,
.altai-theme-content #bodyView h3,
.altai-theme-content #bodyView h4,
.altai-theme-content #bodyView h5,
.altai-theme-content #bodyView h6,
.altai-theme-content #bodyView p,
.altai-theme-content #bodyView ul,
.altai-theme-content #bodyView a {
  margin: 0;
  padding: 0;
}
.altai-theme-content #bodyView .clearfix {
  overflow: hidden;
}
@media all and (max-width: 768px) {
  .altai-theme-content #bodyView html {
    font-size: 87.5%;
  }
}
.altai-theme-content #bodyView body {
  font-size: 1em;
  line-height: 1.625em;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: 'Campton', 'Lora', 'Helvetica', 'Arial', sans-serif;
  color: #767676;
  font-weight: 400;
}
.altai-theme-content #bodyView h1,
.altai-theme-content #bodyView h2,
.altai-theme-content #bodyView h3,
.altai-theme-content #bodyView h4,
.altai-theme-content #bodyView h5,
.altai-theme-content #bodyView h6,
.altai-theme-content #bodyView .h1,
.altai-theme-content #bodyView .h2,
.altai-theme-content #bodyView .h3,
.altai-theme-content #bodyView .h4,
.altai-theme-content #bodyView .h5,
.altai-theme-content #bodyView .h6 {
  font-family: 'Campton', 'Campton', 'Helvetica', 'Arial', sans-serif;
  font-weight: 600;
  font-variant-ligatures: common-ligatures;
  margin-top: 0;
  margin-bottom: 0;
}
.altai-theme-content #bodyView h1,
.altai-theme-content #bodyView .h1 {
  font-size: 2em;
  line-height: 1.015625em;
}
.altai-theme-content #bodyView h2,
.altai-theme-content #bodyView .h2 {
  font-size: 1.3125em;
  line-height: 1.15555556em;
}
.altai-theme-content #bodyView h4,
.altai-theme-content #bodyView .h4 {
  font-size: 1.3125em;
  line-height: 1.15555556em;
}
.altai-theme-content #bodyView h4 strong,
.altai-theme-content #bodyView .h4 strong {
  font-weight: 600;
}
.altai-theme-content #bodyView h3,
.altai-theme-content #bodyView .h3 {
  font-size: 1.1em;
  line-height: 1.421875em;
}
.altai-theme-content #bodyView h5,
.altai-theme-content #bodyView .h5 {
  font-size: 1.05em;
  line-height: 1.625em;
}
.altai-theme-content #bodyView h6,
.altai-theme-content #bodyView .h6 {
  font-size: 0.625em;
  line-height: 2.6em;
}
.altai-theme-content #bodyView .lead {
  font-size: 1.4375em;
  line-height: 1.69565217em;
}
@media all and (max-width: 767px) {
  .altai-theme-content #bodyView h1,
  .altai-theme-content #bodyView .h1 {
    font-size: 2.8125em;
    line-height: 1.15555556em;
  }
  .altai-theme-content #bodyView h2,
  .altai-theme-content #bodyView .h2 {
    font-size: 2em;
    line-height: 1.421875em;
  }
  .altai-theme-content #bodyView h3,
  .altai-theme-content #bodyView .h3 {
    font-size: 1.4375em;
    line-height: 1.625em;
  }
  .altai-theme-content #bodyView .lead {
    font-size: 1.4375em;
    line-height: 1.41304348em;
  }
}
.altai-theme-content #bodyView p,
.altai-theme-content #bodyView ul,
.altai-theme-content #bodyView ol,
.altai-theme-content #bodyView pre,
.altai-theme-content #bodyView table,
.altai-theme-content #bodyView blockquote {
  margin-bottom: 1.625em;
}
.altai-theme-content #bodyView ul,
.altai-theme-content #bodyView ol {
  list-style: none;
}
.altai-theme-content #bodyView ul:not(.menu):not(.slides):not(.accordion):not(.tabs):not(.tabs-content),
.altai-theme-content #bodyView ol:not(.menu):not(.slides):not(.accordion):not(.tabs):not(.tabs-content) {
  line-height: 2.34375em;
}
.altai-theme-content #bodyView ul.bullets {
  list-style: inside;
}
.altai-theme-content #bodyView ol {
  list-style-type: upper-roman;
  list-style-position: inside;
}
.altai-theme-content #bodyView blockquote {
  font-size: 2em;
  line-height: 1.421875em;
  padding: 0;
  margin: 0;
  border-left: 0;
}
.altai-theme-content #bodyView strong {
  font-weight: 600;
}
.altai-theme-content #bodyView hr {
  margin: 1.625em 0;
  border-color: #f4f4f4;
}
.altai-theme-content #bodyView a:hover,
.altai-theme-content #bodyView a:focus,
.altai-theme-content #bodyView a:active {
  text-decoration: none;
  outline: none;
}
.altai-theme-content #bodyView .type--uppercase {
  text-transform: uppercase;
}
.altai-theme-content #bodyView .type--bold {
  font-weight: bold;
}
.altai-theme-content #bodyView .type--italic {
  font-weight: italic;
}
.altai-theme-content #bodyView .type--fine-print {
  font-size: 0.6875em;
}
.altai-theme-content #bodyView .type--strikethrough {
  text-decoration: line-through;
  opacity: .5;
}
.altai-theme-content #bodyView .type--underline {
  text-decoration: underline;
}
.altai-theme-content #bodyView .type--body-font {
  font-family: 'Lora', 'Helvetica';
}
.altai-theme-content #bodyView body {
  overflow-x: hidden;
}
.altai-theme-content #bodyView .text-center {
  text-align: center !important;
}
.altai-theme-content #bodyView .text-left {
  text-align: left;
}
.altai-theme-content #bodyView .text-right {
  text-align: right;
}
.altai-theme-content #bodyView img {
  max-width: 100%;
  margin-bottom: 1.625em;
}
.altai-theme-content #bodyView body {
  background: #f8f8f8;
}
.altai-theme-content #bodyView a {
  color: #428fbb;
  cursor: pointer;
}
.altai-theme-content #bodyView .bg--dark {
  background: #000000;
}
.altai-theme-content #bodyView .bg--dark *:not(a) {
  color: #f5f5f5;
}
.altai-theme-content #bodyView .bg--dark a:not(.btn) {
  color: #fff;
}
.altai-theme-content #bodyView .bg--white {
  background: #fff;
}
.altai-theme-content #bodyView .bg--site {
  background: #f8f8f8;
}
.altai-theme-content #bodyView .bg--secondary {
  background: #f4f4f4;
}
.altai-theme-content #bodyView .bg--white {
  background: #fff;
}
.altai-theme-content #bodyView .bg--white p,
.altai-theme-content #bodyView .bg--white span,
.altai-theme-content #bodyView .bg--white ul,
.altai-theme-content #bodyView .bg--white a:not(.btn) {
  color: #111;
}
.altai-theme-content #bodyView .bg--white h1,
.altai-theme-content #bodyView .bg--white h2,
.altai-theme-content #bodyView .bg--white h3,
.altai-theme-content #bodyView .bg--white h4,
.altai-theme-content #bodyView .bg--white h5,
.altai-theme-content #bodyView .bg--white h6,
.altai-theme-content #bodyView .bg--white i {
  color: #232323;
}
.altai-theme-content #bodyView .image-bg:not(.image-light) *:not(a) {
  color: #fff;
}
.altai-theme-content #bodyView .btn {
  font-family: 'Campton', 'Helvetica', 'Arial', sans-serif;
  transition: 0.1s linear;
  -webkit-transition: 0.1s linear;
  -moz-transition: 0.1s linear;
  border-radius: 100px;
  padding-top: 0.54166667em;
  padding-bottom: 0.65em;
  padding-right: 3.25em;
  padding-left: 3.25em;
  border: 1px solid #232323;
  border-width: 2px;
  font-size: inherit;
  line-height: 1.625em;
}
.altai-theme-content #bodyView .btn .btn__text,
.altai-theme-content #bodyView .btn i {
  color: #343434;
  border-color: #343434;
  font-weight: 400;
  font-size: 0.625em;
}
.altai-theme-content #bodyView .btn + .btn {
  margin-left: 1.625em;
}
.altai-theme-content #bodyView .btn[class*='col-'] {
  margin-left: 0;
  margin-right: 0;
}
.altai-theme-content #bodyView .btn:active {
  box-shadow: none;
  -webkit-box-shadow: none;
}
.altai-theme-content #bodyView .btn:first-child {
  margin-left: 0;
}
.altai-theme-content #bodyView .btn:last-child {
  margin-right: 0;
}
.altai-theme-content #bodyView .btn--xs {
  padding-top: 0;
  padding-bottom: 0;
  padding-right: 1.625em;
  padding-left: 1.625em;
}
.altai-theme-content #bodyView .btn--sm {
  padding-top: 0.36111111em;
  padding-bottom: 0.43333333em;
  padding-right: 2.16666667em;
  padding-left: 2.16666667em;
}
.altai-theme-content #bodyView .btn--lg {
  padding-top: 0.67708333em;
  padding-bottom: 0.8125em;
  padding-right: 4.0625em;
  padding-left: 4.0625em;
}
.altai-theme-content #bodyView .btn--lg .btn__text {
  font-size: 0.78125em;
}
.altai-theme-content #bodyView .btn--transparent {
  background: none;
  border-color: rgba(0, 0, 0, 0);
  padding-left: 0;
  padding-right: 0;
}
.altai-theme-content #bodyView .btn--transparent.btn--white .btn__text {
  color: #fff;
}
.altai-theme-content #bodyView .dropdown {
  margin: 10px;
}
.altai-theme-content #bodyView ul.dropdown-menu {
  margin: 0;
  padding: 0;
}
.altai-theme-content #bodyView ul.scroll {
  margin: 0;
  padding: 0;
  min-width: 300px;
  max-height: 500px;
  /* you can change as you need it */
  overflow-y: auto;
  /* to get scroll */
  overflow-x: hidden;
}
.altai-theme-content #bodyView .list-group-item {
  font-family: 'Campton', 'Helvetica', 'Arial', sans-serif;
  width: 100%;
  min-width: 100%;
  white-space: nowrap;
  display: inline-block;
  margin-right: 50px;
  border: none;
}
.altai-theme-content #bodyView .list-group-item .badge {
  margin-top: 0.3em;
}
.altai-theme-content #bodyView .list-group-item i {
  font-size: 1.2em;
  margin-right: 5px;
}
.altai-theme-content #bodyView .list-group-item:hover {
  font-family: 'Campton', 'Helvetica', 'Arial', sans-serif;
  background: #505050;
  border-color: #505050;
  color: #FFF;
}
.altai-theme-content #bodyView .list-group-item:hover .badge {
  background: #FFF;
  color: #505050;
}
.altai-theme-content #bodyView .list-group-item.active {
  font-family: 'Campton', 'Helvetica', 'Arial', sans-serif;
  background: #252525 !important;
  border-color: #252525 !important;
  font-weight: bold;
  color: #FFF;
}
.altai-theme-content #bodyView .list-group-item.active .badge {
  background: #FFF;
  color: #505050;
}
.altai-theme-content #bodyView .list-group-item.child {
  font-size: 80%;
  text-indent: 2em;
}
.altai-theme-content #bodyView .list-group-item.child .badge {
  text-indent: 0;
}
.altai-theme-content #bodyView .list-group-item:first-child {
  border-top-right-radius: 0px;
  border-top-left-radius: 0px;
}
.altai-theme-content #bodyView .list-group-item:last-child {
  border-top-right-radius: 0px;
  border-top-left-radius: 0px;
}
.altai-theme-content #bodyView .search-bar {
  background: #F3F3F3 !important;
  color: #252525;
  font-weight: bold;
  margin: 5px;
  width: 100%;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -o-box-sizing: border-box;
  -ms-box-sizing: border-box;
  box-sizing: border-box;
}
.altai-theme-content #bodyView input[type="text"].search-bar {
  font-family: 'Campton', 'Helvetica', 'Arial', sans-serif;
  color: black;
}
.altai-theme-content #bodyView input[type="text"].search-bar::-webkit-input-placeholder {
  font-family: 'Campton', 'Helvetica', 'Arial', sans-serif;
  color: dimgray;
}
.altai-theme-content #bodyView input[type="text"].search-ba::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  font-family: 'Campton', 'Helvetica', 'Arial', sans-serif;
  color: dimgray;
}
.altai-theme-content #bodyView input[type="text"].search-ba::-moz-placeholder {
  /* Firefox 19+ */
  font-family: 'Campton', 'Helvetica', 'Arial', sans-serif;
  color: dimgray;
}
.altai-theme-content #bodyView input[type="text"].search-ba:-ms-input-placeholder {
  /* IE 10+ */
  font-family: 'Campton', 'Helvetica', 'Arial', sans-serif;
  color: dimgray;
}
.altai-theme-content #bodyView input[type="text"].search-ba:-moz-placeholder {
  /* Firefox 18- */
  font-family: 'Campton', 'Helvetica', 'Arial', sans-serif;
  color: dimgray;
}
.altai-theme-content #bodyView .icon {
  line-height: 1em;
  font-size: 2.8125em;
}
.altai-theme-content #bodyView nav {
  background: none;
  z-index: 10;
}
.altai-theme-content #bodyView .nav-bar {
  height: 65px;
  max-height: 65px;
  line-height: 62px;
}
.altai-theme-content #bodyView .nav-bar .logo {
  max-height: 40%;
  margin: 0;
}
.altai-theme-content #bodyView .nav-bar .nav-module {
  display: inline-block;
  height: 65px;
  padding: 0 1em;
}
.altai-theme-content #bodyView .nav-bar .nav-module > a:not(.btn) {
  height: 100%;
  display: inline-block;
}
.altai-theme-content #bodyView .nav-bar.nav--fixed {
  position: fixed;
  width: 100%;
  left: 0;
  z-index: 999;
  top: 0;
}
.altai-theme-content #bodyView .nav-bar.nav--fixed:not(.bg--dark):not(.bg--primary):not(.bg--white) {
  background: #f8f8f8;
}
.altai-theme-content #bodyView .nav-bar.nav--fixed + .nav-mobile-toggle {
  position: fixed;
  top: 0;
  right: 0;
  z-index: 999;
  color: #222;
}
.altai-theme-content #bodyView .nav-module.left {
  float: left;
}
.altai-theme-content #bodyView .nav-module.right {
  float: right;
}
.altai-theme-content #bodyView .nav-module:first-of-type {
  padding-left: 0;
}
.altai-theme-content #bodyView .nav-module:last-of-type {
  padding-right: 0;
}
.altai-theme-content #bodyView .nav-bar,
.altai-theme-content #bodyView .utility-bar {
  padding: 0 3.25em 0 3.25em;
}
.altai-theme-content #bodyView .utility-bar {
  overflow: hidden;
}
.altai-theme-content #bodyView .utility-bar:not(.hidden) + .nav-bar + .nav-mobile-toggle {
  top: 65px;
}
@media all and (max-width: 1280px) {
  .altai-theme-content #bodyView .nav-bar {
    padding: 0 15px;
  }
}
.altai-theme-content #bodyView .container .nav-bar {
  padding: 0;
}
.altai-theme-content #bodyView .menu {
  height: 65px;
}
.altai-theme-content #bodyView .menu > li {
  float: left;
  font-size: 10px;
  font-weight: 400;
  letter-spacing: 0px;
  font-family: 'Campton', 'Helvetica', 'Arial', sans-serif;
}
.altai-theme-content #bodyView .menu > li > a {
  padding: 0 1.3em;
  height: 100%;
  display: inline-block;
}
.altai-theme-content #bodyView .menu > li:first-child > a {
  padding-left: 0;
}
.altai-theme-content #bodyView .menu > li:last-child a {
  padding-right: 0;
}
.altai-theme-content #bodyView .nav-bar.bg--white .menu > li > a {
  color: #767676;
}
.altai-theme-content #bodyView .nav-bar.bg--dark .menu > li > a {
  color: #fff;
}
.altai-theme-content #bodyView .menu > li ul {
  left: 0;
  margin: 0;
  line-height: 1.625em !important;
  padding: 1em 0;
  background: none;
  opacity: 0;
  visibility: hidden;
  position: absolute;
  min-width: 13em;
  float: left;
}
.altai-theme-content #bodyView .menu > li > ul a {
  padding: 0 1em 1.625em 1em;
  display: inline-block;
}
.altai-theme-content #bodyView .menu > li > ul li:last-child > a {
  padding-bottom: 0;
}
.altai-theme-content #bodyView .menu > li:hover > ul,
.altai-theme-content #bodyView .menu > li > ul > li:hover ul {
  opacity: 1;
  visibility: visible;
  z-index: 10;
}
.altai-theme-content #bodyView .menu > li:hover .multi-column ul {
  position: relative !important;
  visibility: visible !important;
  opacity: 1 !important;
}
.altai-theme-content #bodyView .menu > li > ul:not(.multi-column) ul {
  top: 0;
  left: 100%;
}
@media all and (max-width: 990px) {
  .altai-theme-content #bodyView .nav-bar {
    height: auto;
    overflow: hidden;
  }
  .altai-theme-content #bodyView .nav-bar .nav-module.left,
  .altai-theme-content #bodyView .nav-bar .nav-module.right {
    float: none;
    display: block;
  }
  .altai-theme-content #bodyView .nav-bar .nav-module {
    padding: 0;
  }
  .altai-theme-content #bodyView .nav-bar .nav-module:not(.logo-module) {
    height: auto;
    line-height: 2em;
  }
  .altai-theme-content #bodyView .menu {
    height: auto;
  }
  .altai-theme-content #bodyView .menu li {
    float: none;
    width: 100%;
  }
  .altai-theme-content #bodyView .menu > li > a {
    padding: 0;
  }
  .altai-theme-content #bodyView .menu > li ul {
    position: relative;
    float: none;
    display: none;
    width: 100%;
  }
  .altai-theme-content #bodyView .menu > li ul ul {
    left: 0 !important;
    padding-left: 2em;
  }
  .altai-theme-content #bodyView .menu > li > ul:not(.multi-column) ul {
    padding-top: 0;
    padding-bottom: 1.5em;
  }
  .altai-theme-content #bodyView .menu li.active > ul,
  .altai-theme-content #bodyView .menu li.active .multi-column ul {
    display: block;
    opacity: 1;
    visibility: visible;
  }
  .altai-theme-content #bodyView .menu .multi-column {
    white-space: initial;
  }
  .altai-theme-content #bodyView .menu .multi-column ul {
    padding-top: 0;
    padding-bottom: 0;
  }
  .altai-theme-content #bodyView .menu .multi-column ul li a {
    padding-left: 0;
  }
  .altai-theme-content #bodyView .menu .multi-column ul li:last-child a {
    padding-bottom: 1.625em;
  }
  .altai-theme-content #bodyView .module-applet {
    padding: 0;
  }
  .altai-theme-content #bodyView .module-applet .module-applet__body {
    position: relative;
    display: none;
    padding: 1em 0;
    opacity: 1;
    visibility: visible;
  }
  .altai-theme-content #bodyView .module-applet.active .module-applet__body {
    display: block;
  }
}
.altai-theme-content #bodyView .nav-open .nav-bar {
  max-height: 100000px !important;
}
.altai-theme-content #bodyView .nav--fixed,
.altai-theme-content #bodyView .nav--absolute {
  width: 100%;
  z-index: 99;
}
.altai-theme-content #bodyView .nav--fixed {
  position: fixed;
}
.altai-theme-content #bodyView .nav--absolute {
  position: absolute;
}
.altai-theme-content #bodyView .nav--transparent:not(.nav--fixed) {
  background: none !important;
}
.altai-theme-content #bodyView nav:not(.nav--fixed):not(.nav--absolute) {
  position: relative;
}
@media all and (max-width: 990px) {
  .altai-theme-content #bodyView .nav-bar.nav--absolute,
  .altai-theme-content #bodyView .nav-bar.nav--fixed {
    position: relative;
  }
  .altai-theme-content #bodyView .nav-bar.nav--absolute + .nav-mobile-toggle,
  .altai-theme-content #bodyView .nav-bar.nav--fixed + .nav-mobile-toggle {
    position: absolute;
  }
}
.altai-theme-content #bodyView form {
  max-width: 100%;
  overflow: hidden;
}
.altai-theme-content #bodyView form + form {
  margin-top: 30px;
}
.altai-theme-content #bodyView label {
  margin: 0;
  font-size: 1em;
  font-weight: 400;
}
.altai-theme-content #bodyView input[type="text"],
.altai-theme-content #bodyView input[type="email"],
.altai-theme-content #bodyView input[type="password"],
.altai-theme-content #bodyView textarea,
.altai-theme-content #bodyView select,
.altai-theme-content #bodyView input[type="tel"],
.altai-theme-content #bodyView input[type="url"],
.altai-theme-content #bodyView input[type="search"] {
  padding: 0.8125em;
  border: none;
  outline: none;
}
.altai-theme-content #bodyView .form-control:focus {
  border-color: transparent;
  border-width: 0;
  outline: 0;
  background: #f5f5f5;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
}
.altai-theme-content #bodyView input[type="text"]:not(button),
.altai-theme-content #bodyView input[type="email"]:not(button),
.altai-theme-content #bodyView input[type="password"]:not(button),
.altai-theme-content #bodyView textarea:not(button),
.altai-theme-content #bodyView select:not(button),
.altai-theme-content #bodyView input[type="tel"]:not(button),
.altai-theme-content #bodyView input[type="url"]:not(button),
.altai-theme-content #bodyView input[type="search"]:not(button) {
  background: #f9f9f9;
}
.altai-theme-content #bodyView .bg--site input[type="text"]:not(button),
.altai-theme-content #bodyView .bg--site input[type="email"]:not(button),
.altai-theme-content #bodyView .bg--site input[type="password"]:not(button),
.altai-theme-content #bodyView .bg--site textarea:not(button),
.altai-theme-content #bodyView .bg--site select:not(button),
.altai-theme-content #bodyView .bg--site input[type="tel"]:not(button),
.altai-theme-content #bodyView .bg--site input[type="url"]:not(button),
.altai-theme-content #bodyView .bg--site input[type="search"]:not(button) {
  background: #fff;
}
.altai-theme-content #bodyView input[type="text"]:focus,
.altai-theme-content #bodyView input[type="email"]:focus,
.altai-theme-content #bodyView input[type="password"]:focus,
.altai-theme-content #bodyView textarea:focus,
.altai-theme-content #bodyView select:focus,
.altai-theme-content #bodyView input[type="tel"]:focus,
.altai-theme-content #bodyView input[type="url"]:focus,
.altai-theme-content #bodyView input[type="search"]:focus {
  outline: none;
}
.altai-theme-content #bodyView input[type="text"]:active,
.altai-theme-content #bodyView input[type="email"]:active,
.altai-theme-content #bodyView input[type="password"]:active,
.altai-theme-content #bodyView textarea:active,
.altai-theme-content #bodyView select:active,
.altai-theme-content #bodyView input[type="tel"]:active,
.altai-theme-content #bodyView input[type="url"]:active,
.altai-theme-content #bodyView input[type="search"]:active {
  outline: none;
}
.altai-theme-content #bodyView input[type="text"]::-webkit-input-placeholder,
.altai-theme-content #bodyView input[type="email"]::-webkit-input-placeholder,
.altai-theme-content #bodyView input[type="password"]::-webkit-input-placeholder,
.altai-theme-content #bodyView textarea::-webkit-input-placeholder,
.altai-theme-content #bodyView select::-webkit-input-placeholder,
.altai-theme-content #bodyView input[type="tel"]::-webkit-input-placeholder,
.altai-theme-content #bodyView input[type="url"]::-webkit-input-placeholder,
.altai-theme-content #bodyView input[type="search"]::-webkit-input-placeholder {
  color: #c3c3c3;
  font-size: 1em;
}
.altai-theme-content #bodyView input[type="text"]:-moz-placeholder,
.altai-theme-content #bodyView input[type="email"]:-moz-placeholder,
.altai-theme-content #bodyView input[type="password"]:-moz-placeholder,
.altai-theme-content #bodyView textarea:-moz-placeholder,
.altai-theme-content #bodyView select:-moz-placeholder,
.altai-theme-content #bodyView input[type="tel"]:-moz-placeholder,
.altai-theme-content #bodyView input[type="url"]:-moz-placeholder,
.altai-theme-content #bodyView input[type="search"]:-moz-placeholder {
  /* Firefox 18- */
  color: #c3c3c3;
  font-size: 1em;
}
.altai-theme-content #bodyView input[type="text"]::-moz-placeholder,
.altai-theme-content #bodyView input[type="email"]::-moz-placeholder,
.altai-theme-content #bodyView input[type="password"]::-moz-placeholder,
.altai-theme-content #bodyView textarea::-moz-placeholder,
.altai-theme-content #bodyView select::-moz-placeholder,
.altai-theme-content #bodyView input[type="tel"]::-moz-placeholder,
.altai-theme-content #bodyView input[type="url"]::-moz-placeholder,
.altai-theme-content #bodyView input[type="search"]::-moz-placeholder {
  /* Firefox 19+ */
  color: #c3c3c3;
  font-size: 1em;
}
.altai-theme-content #bodyView input[type="text"]:-ms-input-placeholder,
.altai-theme-content #bodyView input[type="email"]:-ms-input-placeholder,
.altai-theme-content #bodyView input[type="password"]:-ms-input-placeholder,
.altai-theme-content #bodyView textarea:-ms-input-placeholder,
.altai-theme-content #bodyView select:-ms-input-placeholder,
.altai-theme-content #bodyView input[type="tel"]:-ms-input-placeholder,
.altai-theme-content #bodyView input[type="url"]:-ms-input-placeholder,
.altai-theme-content #bodyView input[type="search"]:-ms-input-placeholder {
  color: #c3c3c3;
  font-size: 1em;
}
.altai-theme-content #bodyView textarea {
  display: block;
  width: 100%;
  max-width: 100%;
}
.altai-theme-content #bodyView select {
  cursor: pointer;
  padding-right: 3.25em;
  -webkit-appearance: none;
}
.altai-theme-content #bodyView select::ms-expand {
  display: none;
}
.altai-theme-content #bodyView input[type="submit"] {
  background: none;
  outline: none;
  border: none;
  background: #428fbb;
  padding: 0.8125em 3.25em 0.8125em 3.25em;
  color: #fff;
}
@keyframes load {
  0% {
    opacity: 0;
    width: 0;
  }
  50% {
    width: 100%;
    opacity: .8;
    left: 0;
  }
  100% {
    left: 100%;
    opacity: 0;
  }
}
.altai-theme-content #bodyView button {
  background: none;
}
.altai-theme-content #bodyView button:focus {
  outline: none !important;
}
.altai-theme-content #bodyView .modal-instance .modal-body {
  display: none;
}
.altai-theme-content #bodyView section,
.altai-theme-content #bodyView .section,
.altai-theme-content #bodyView footer {
  padding-top: 6.5em;
  padding-bottom: 8.125em;
}
.altai-theme-content #bodyView section.space--sm,
.altai-theme-content #bodyView footer.space--sm {
  padding-top: 4.33333333em;
  padding-bottom: 4.33333333em;
}
.altai-theme-content #bodyView section.space--lg,
.altai-theme-content #bodyView footer.space--lg {
  padding-top: 13em;
  padding-bottom: 13em;
}
.altai-theme-content #bodyView section.space--xlg,
.altai-theme-content #bodyView footer.space--xlg {
  padding-top: 26em;
  padding-bottom: 32.5em;
}
.altai-theme-content #bodyView section.space--0,
.altai-theme-content #bodyView footer.space--0 {
  padding: 0;
}
.altai-theme-content #bodyView section.section--even,
.altai-theme-content #bodyView footer.section--even {
  padding-top: 8.125em;
  padding-bottom: 8.125em;
}
.altai-theme-content #bodyView section.space-bottom--sm,
.altai-theme-content #bodyView footer.space-bottom--sm {
  padding-bottom: 4.33333333em;
}
@media all and (max-width: 767px) {
  .altai-theme-content #bodyView section,
  .altai-theme-content #bodyView .section,
  .altai-theme-content #bodyView footer,
  .altai-theme-content #bodyView section.section--even {
    /*padding: 0em 0;*/
  }
  .altai-theme-content #bodyView section.space--lg,
  .altai-theme-content #bodyView footer.space--lg,
  .altai-theme-content #bodyView section.section--even.space--lg {
    /*padding: 0em 0;*/
  }
  .altai-theme-content #bodyView section.space--xlg,
  .altai-theme-content #bodyView footer.space--xlg,
  .altai-theme-content #bodyView section.section--even.space--xlg {
    /*padding: 0em 0;*/
  }
}
.altai-theme-content #bodyView div[class*='col-'] > div[class*='col-']:first-child {
  padding-left: 0;
}
.altai-theme-content #bodyView div[class*='col-'] > div[class*='col-']:last-child {
  padding-right: 0;
}
@media all and (max-width: 767px) {
  .altai-theme-content #bodyView .col-xs-6:nth-child(odd) {
    padding-right: 7.5px;
  }
  .altai-theme-content #bodyView .col-xs-6:nth-child(even) {
    padding-left: 7.5px;
  }
}
@media all and (max-width: 1024px) {
  .altai-theme-content #bodyView html {
    font-size: 87.5%;
  }
}
.altai-theme-content #bodyView h1,
.altai-theme-content #bodyView h2,
.altai-theme-content #bodyView h3,
.altai-theme-content #bodyView h4,
.altai-theme-content #bodyView h5,
.altai-theme-content #bodyView h6,
.altai-theme-content #bodyView .h1,
.altai-theme-content #bodyView .h2,
.altai-theme-content #bodyView .h3,
.altai-theme-content #bodyView .h4,
.altai-theme-content #bodyView .h5,
.altai-theme-content #bodyView .h6 {
  margin-top: 0;
}
.altai-theme-content #bodyView h1,
.altai-theme-content #bodyView .h1 {
  margin-bottom: 0.40625em;
}
.altai-theme-content #bodyView h2,
.altai-theme-content #bodyView .h2 {
  margin-bottom: 0.57777777777778em;
}
.altai-theme-content #bodyView h3,
.altai-theme-content #bodyView .h3 {
  margin-bottom: 0.8125em;
}
.altai-theme-content #bodyView h4,
.altai-theme-content #bodyView .h4 {
  margin-bottom: 1.1304347826087em;
}
.altai-theme-content #bodyView h5,
.altai-theme-content #bodyView .h5 {
  margin-bottom: 1.625em;
}
.altai-theme-content #bodyView h6,
.altai-theme-content #bodyView .h6 {
  letter-spacing: 1px;
  font-weight: 400;
  margin-bottom: 2.36363636363636em;
}
@media all and (max-width: 767px) {
  .altai-theme-content #bodyView h1,
  .altai-theme-content #bodyView .h1 {
    font-size: 2em;
    line-height: 1.421875em;
  }
}
.altai-theme-content #bodyView a {
  color: #232323;
}
.altai-theme-content #bodyView a:hover {
  color: #232323;
}
.altai-theme-content #bodyView span a,
.altai-theme-content #bodyView p a {
  text-decoration: underline;
}
.altai-theme-content #bodyView span a:hover,
.altai-theme-content #bodyView p a:hover {
  text-decoration: underline;
  opacity: .9;
}
.altai-theme-content #bodyView blockquote {
  font-size: 0.85em;
  line-height: 1em;
  font-style: italic;
  /* margin-bottom: 1.1304347826087em; */
  background-color: #f2f2f2;
  padding: 10px 20px 20px 20px;
  margin: 40px 0 60px 0;
  border-left: 10px solid #333333;
}
.altai-theme-content #bodyView span.label {
  display: inline-block;
  font-family: 'Campton', 'Helvetica', 'Arial', sans-serif;
  color: #343434;
  font-weight: 600;
  font-variant-ligatures: common-ligatures;
  margin-bottom: 0;
  font-size: 0.625em;
  line-height: 2.6em;
  margin-top: 0;
  letter-spacing: 1px;
  font-weight: 400;
  margin-bottom: 2.36363636363636em;
  font-size: 10px;
  padding: 0 8px;
  color: #fff !important;
  background: #64b671;
  position: absolute;
  top: 13px;
  right: 13px;
  margin: 0;
}
.altai-theme-content #bodyView .menu li a + span.label {
  right: 26px;
  top: 0;
  max-height: 20px;
  line-height: 20px;
}
@media all and (max-width: 767px) {
  .altai-theme-content #bodyView .btn + .btn {
    margin-left: 0;
    margin-top: 4px;
  }
}
.altai-theme-content #bodyView .logo {
  margin: 0;
}
.altai-theme-content #bodyView .nav-bar {
  padding-right: 0;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.05);
}
.altai-theme-content #bodyView .nav-bar .logo {
  margin-right: 1.625em;
}
.altai-theme-content #bodyView .nav-bar .logo-light {
  display: none;
}
.altai-theme-content #bodyView .nav-bar .logo-dark {
  display: inline;
}
.altai-theme-content #bodyView .nav-bar.nav--absolute {
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}
.altai-theme-content #bodyView .nav-bar.nav--absolute:not(.nav--fixed) .nav-module.right:not(.logo-module):not(.menu-module):not( :nth-last-child(2)) {
  border-left: 1px solid rgba(255, 255, 255, 0.1);
}
.altai-theme-content #bodyView .nav-bar .nav-module.right:not(.logo-module):not(.menu-module) {
  padding: 0 1.625em;
  transition: 0.2s linear;
  -webkit-transition: 0.2s linear;
  -moz-transition: 0.2s linear;
}
.altai-theme-content #bodyView .nav-bar .nav-module.right:not(.logo-module):not(.menu-module):not( :nth-last-child(2)) {
  border-left: 1px solid rgba(0, 0, 0, 0.05);
  padding-right: 0;
}
.altai-theme-content #bodyView .nav-bar.nav--fixed {
  box-shadow: 0px 0px 60px 0px rgba(0, 0, 0, 0.07);
  border-bottom: none;
}
.altai-theme-content #bodyView .nav-bar.nav--fixed:not(.no-animation) {
  animation: navslide .5s ease forwards;
}
.altai-theme-content #bodyView .nav-bar.bg--dark .logo-light {
  display: inline;
}
.altai-theme-content #bodyView .nav-bar.bg--dark .logo-dark {
  display: none;
}
.altai-theme-content #bodyView .nav-bar a.nav-function {
  transition: 0.2s linear;
  -webkit-transition: 0.2s linear;
  -moz-transition: 0.2s linear;
}
.altai-theme-content #bodyView .nav-bar a.nav-function span {
  font-size: 10px;
  font-family: 'Campton';
  letter-spacing: 1px;
  margin-left: 0.8125em;
  display: none;
}
.altai-theme-content #bodyView .nav-bar a.nav-function i {
  font-size: 1.1em;
  display: inline-block;
  position: relative;
  top: 3px;
}
.altai-theme-content #bodyView .nav-bar a.nav-function:hover {
  opacity: .5;
}
@media all and (min-width: 991px) {
  .altai-theme-content #bodyView .nav-bar.nav--transparent:not(.nav--scrolled):not(.nav--fixed) .menu > li > a {
    color: #fff;
  }
  .altai-theme-content #bodyView .nav-bar.nav--transparent:not(.nav--scrolled):not(.nav--fixed) .logo-dark {
    display: none;
  }
  .altai-theme-content #bodyView .nav-bar.nav--transparent:not(.nav--scrolled):not(.nav--fixed) .logo-light {
    display: inline;
  }
  .altai-theme-content #bodyView .nav-bar.nav--transparent:not(.nav--scrolled):not(.nav--fixed) a.nav-function i,
  .altai-theme-content #bodyView .nav-bar.nav--transparent:not(.nav--scrolled):not(.nav--fixed) a.nav-function span {
    color: #fff;
  }
}
@media all and (max-width: 767px) {
  .altai-theme-content #bodyView .nav-bar a.nav-function span {
    display: inline-block;
  }
}
@media all and (max-width: 990px) {
  .altai-theme-content #bodyView .nav-bar {
    padding: 0 15px;
  }
  .altai-theme-content #bodyView .nav-bar .nav-module.right:not(.logo-module):not(.menu-module) {
    padding: 0;
    margin-bottom: 0.8125em;
  }
}
.altai-theme-content #bodyView .menu > li {
  transition: 0.3s linear;
  -webkit-transition: 0.3s linear;
  -moz-transition: 0.3s linear;
}
.altai-theme-content #bodyView .menu > li:hover > a {
  opacity: .6;
}
.altai-theme-content #bodyView .menu > li a {
  letter-spacing: 1px;
  font-weight: 500;
}
.altai-theme-content #bodyView .darkbg .menu > li a {
  letter-spacing: 1px;
  font-weight: 500;
}
.altai-theme-content #bodyView .menu > li ul {
  white-space: nowrap;
  margin-top: 3px;
  padding: 1.625em;
  min-width: 16em;
}
.altai-theme-content #bodyView .menu > li ul li {
  padding-left: 0.42307692307692em;
}
.altai-theme-content #bodyView .menu > li ul li a {
  padding-left: 0;
  width: 100%;
  color: #fff;
}
.altai-theme-content #bodyView .menu > li > ul {
  box-shadow: 0px 0px 20px 0px rgba(255, 255, 255, 0.05);
}
.altai-theme-content #bodyView .menu > li > ul:not(.multi-column) > li.dropdown > a:after {
  content: '';
  display: block;
  width: 6px;
  height: 6px;
  background: #fff;
  position: absolute;
  right: 0;
  top: 4px;
  opacity: .4;
  transition: 0.4s ease;
  -webkit-transition: 0.4s ease;
  -moz-transition: 0.4s ease;
  transform: translate3d(-10px, 0, 0);
  -webkit-transform: translate3d(-10px, 0, 0);
}
.altai-theme-content #bodyView .menu > li > ul:not(.multi-column) > li.dropdown:hover > a {
  transform: translate3d(5px, 0, 0);
  -webkit-transform: translate3d(5px, 0, 0);
  opacity: 1;
}
.altai-theme-content #bodyView .menu > li > ul:not(.multi-column) > li.dropdown:hover > a:after {
  opacity: 1;
  transform: translate3d(-3px, 0, 0);
  -webkit-transform: translate3d(-3px, 0, 0);
}
.altai-theme-content #bodyView .menu > li > ul:not(.multi-column) ul {
  left: calc(112%);
}
.altai-theme-content #bodyView .menu > li > ul a {
  padding: 0 1em 1.3em 1em;
  transition: 0.2s linear;
  -webkit-transition: 0.2s linear;
  -moz-transition: 0.2s linear;
  opacity: .5;
}
.altai-theme-content #bodyView .menu > li > ul a:hover {
  opacity: 1;
}
@media all and (max-width: 990px) {
  .altai-theme-content #bodyView .menu > li ul ul {
    padding-left: 0;
  }
}
.altai-theme-content #bodyView .nav-bar + .nav-mobile-toggle[data-modal-id] i {
  display: inline-block;
  margin-right: 7px;
}
.altai-theme-content #bodyView .nav-bar + .nav-mobile-toggle[data-modal-id] span {
  position: relative;
  bottom: 10px;
}
@media all and (min-width: 991px) {
  .altai-theme-content #bodyView .nav-bar.nav--transparent:not(.nav--scrolled):not(.nav--fixed) + .nav-mobile-toggle[data-modal-id] {
    color: #fff;
  }
  .altai-theme-content #bodyView .nav-bar.nav--transparent:not(.nav--scrolled):not(.nav--fixed) + .nav-mobile-toggle[data-modal-id] .h6 {
    color: #fff;
  }
}
@media all and (max-width: 767px) {
  .altai-theme-content #bodyView .nav-bar:not(.bg--dark) + .nav-mobile-toggle[data-modal-id] i,
  .altai-theme-content #bodyView .nav-bar:not(.bg--dark) + .nav-mobile-toggle[data-modal-id] .h6 {
    color: #232323 !important;
  }
}
.altai-theme-content #bodyView .btn {
  border-color: rgba(35, 35, 35, 0.1);
}
.altai-theme-content #bodyView .btn .btn__text {
  letter-spacing: 1px;
}
.altai-theme-content #bodyView .btn:hover {
  border-color: rgba(35, 35, 35, 0.9);
}
.altai-theme-content #bodyView .blog-post {
  padding-top: 0;
}
.altai-theme-content #bodyView .blog-post .blog-post__title {
  padding: 9.75em 0 9.75em 0;
}
.altai-theme-content #bodyView .blog-post .blog-post__title:not(.imagebg) + .container {
  position: relative;
  z-index: 4;
  margin-top: -3.25em;
}
.altai-theme-content #bodyView .blog-post .blog-post__title.imagebg {
  padding: 13em 0;
}
.altai-theme-content #bodyView .blog-post .blog-post__title.imagebg span {
  color: #fff;
}
.altai-theme-content #bodyView .blog-post .blog-post__title.imagebg + .container {
  padding-top: 6.5em;
}
.altai-theme-content #bodyView .blog-post .blog-post__author {
  margin-top: 3.25em;
}
.altai-theme-content #bodyView .blog-post .blog-post__author span:not(.h6) {
  font-size: 0.625em;
}
.altai-theme-content #bodyView .blog-post .blog-post__hero {
  margin-bottom: 3.25em;
}
.altai-theme-content #bodyView .blog-post blockquote {
  color: #232323;
  text-align: center;
}
.altai-theme-content #bodyView .blog-post p + blockquote,
.altai-theme-content #bodyView .blog-post img + blockquote {
  margin-top: 3.3913043478261em;
}
.altai-theme-content #bodyView .blog-post blockquote + p,
.altai-theme-content #bodyView .blog-post blockquote + h5,
.altai-theme-content #bodyView .blog-post blockquote + img {
  margin-top: 4.875em;
}
.altai-theme-content #bodyView .blog-post p + h5 {
  margin-top: 3.25em;
}
.altai-theme-content #bodyView .blog-post hr:last-child {
  margin-top: 4.875em;
}
.altai-theme-content #bodyView .blog-post .blog-post__share {
  margin-top: 1.625em;
}
.altai-theme-content #bodyView .blog-post .blog-post__comments {
  margin-top: 3.25em;
}
.altai-theme-content #bodyView .blog-post .blog-post__comments > ul {
  margin-top: 3.25em;
}
.altai-theme-content #bodyView .blog-post .blog-post__comments ul {
  line-height: 1.625em !important;
}
.altai-theme-content #bodyView .blog-post .blog-post__comments ul ul {
  padding-left: 20%;
}
.altai-theme-content #bodyView .blog-post .blog-post__comments .comment {
  overflow: hidden;
}
.altai-theme-content #bodyView .blog-post .blog-post__comments .comment .comment__image {
  display: inline-block;
  width: 20%;
  float: left;
}
.altai-theme-content #bodyView .blog-post .blog-post__comments .comment .comment__image img {
  max-height: 4.875em;
}
.altai-theme-content #bodyView .blog-post .blog-post__comments .comment .comment__text {
  width: 80%;
  float: left;
}
.altai-theme-content #bodyView .blog-post .blog-post__comments .comment .comment__text h5 {
  margin: 0;
}
.altai-theme-content #bodyView .blog-post .blog-post__comments .comment .comment__text h5 + span {
  opacity: .75;
  display: inline-block;
  margin-bottom: 0.8125em;
}
.altai-theme-content #bodyView .blog-post .blog-post__comments .comment hr {
  margin: 2.4375em 0;
  display: block;
  width: 100%;
}
.altai-theme-content #bodyView .blog-post .blog-post__comments .comment__form input {
  width: 100%;
  margin-bottom: 1.625em;
}
.altai-theme-content #bodyView .blog-post .blog-post__comments .comment__form textarea {
  margin-bottom: 1.625em;
}
@media all and (max-width: 767px) {
  .altai-theme-content #bodyView .blog-post .blog-post__share .btn {
    display: block;
    margin: 0 0 0.8125em 0;
  }
  .altai-theme-content #bodyView .blog-post .blog-post__title {
    padding-top: 3.25em;
    padding-bottom: 6.5em;
  }
  .altai-theme-content #bodyView .blog-post .blog-post__title.imagebg {
    padding: 4.875em 0;
  }
  .altai-theme-content #bodyView .blog-post .blog-post__title.imagebg span {
    color: #fff;
  }
  .altai-theme-content #bodyView .blog-post .blog-post__title.imagebg + .container {
    padding-top: 3.25em;
  }
  .altai-theme-content #bodyView .blog-post .blog-post__author {
    margin-top: 1.625em;
  }
}
.altai-theme-content #bodyView footer .logo {
  max-height: 26px;
}
.altai-theme-content #bodyView .card-1 .card__body h5 {
  font-weight: 400;
}
.altai-theme-content #bodyView .card__pills {
  margin-top: 0;
  margin-bottom: 1.8125em;
}
.altai-theme-content #bodyView .tag-pill {
  font-family: 'Campton', 'Helvetica', 'Arial', sans-serif;
  color: #343434;
  font-weight: 600;
  font-variant-ligatures: common-ligatures;
  margin-top: 0;
  margin-bottom: 0;
  font-size: 0.625em;
  line-height: 2.6em;
}
.altai-theme-content #bodyView .tag-pill a {
  border-bottom: none;
}
.altai-theme-content #bodyView .tag-pill a:hover {
  background-color: transparent;
}
.altai-theme-content #bodyView .container-content span.tag-pill--small a {
  border-bottom: none;
}
.altai-theme-content #bodyView .container-content span.tag-pill--small a:hover {
  background-color: transparent;
}
.altai-theme-content #bodyView .tag-pill--small {
  background-color: #d1d1d1;
  padding: 0px 10px;
  border-radius: 6px;
  font-family: 'Campton', 'Helvetica', 'Arial', sans-serif;
  color: #343434;
  font-weight: 600;
  font-variant-ligatures: common-ligatures;
  margin-top: 0;
  margin-bottom: 0;
  font-size: 0.625em;
  line-height: 2.6em;
  letter-spacing: 1px;
  margin-right: 10px;
  font-weight: 400;
  display: inline-block;
  margin-bottom: 3px;
  white-space: nowrap;
}
.altai-theme-content #bodyView .mycomeos-label {
  width: 45px;
  height: 45px;
  font-family: 'Campton', 'Helvetica', 'Arial', sans-serif;
  background-color: rgba(255, 0, 70, 0.7);
  border-radius: 50%;
  color: white;
  z-index: 2;
  position: absolute;
  /* float: right; */
  right: 30px;
  top: 30px;
  padding: 10px 0 0 9px;
}
.altai-theme-content #bodyView .card-text-only .mycomeos-label {
  width: 45px;
  height: 45px;
  font-family: 'Campton', 'Helvetica', 'Arial', sans-serif;
  background-color: rgba(255, 0, 70, 0.7);
  border-radius: 50%;
  color: white;
  z-index: 2;
  position: absolute;
  /* float: right; */
  right: 30px;
  top: -22px;
  padding: 10px 0 0 9px;
}
.altai-theme-content #bodyView .card-text-only .boxed.boxed--sm {
  padding-top: 3em;
}
.altai-theme-content #bodyView .masonry .masonry__container {
  overflow: visible;
}
.altai-theme-content #bodyView .content-type-label {
  background-color: #f3f3f3;
  padding: 5px;
  position: absolute;
  left: 25%;
  width: 50%;
  top: -15px;
  height: 30px;
  z-index: 2;
  font-family: 'Campton', 'Helvetica', 'Arial', sans-serif;
  color: #343434;
  font-variant-ligatures: common-ligatures;
  margin-top: 0;
  margin-bottom: 0;
  font-size: 0.575em;
  line-height: 2.6em;
  letter-spacing: 1px;
  font-weight: 400;
  text-align: center;
  vertical-align: middle;
}
.altai-theme-content #bodyView .card__like {
  float: right;
}
.altai-theme-content #bodyView .card__like .icon {
  font-size: 1.5em;
  padding-right: 10px;
  vertical-align: middle;
  -webkit-text-stroke-width: 0.7px;
  -webkit-text-stroke-color: #646464;
  -webkit-font-smoothing: antialiased;
  color: #646464;
}
.altai-theme-content #bodyView .content-type-label .icon {
  font-size: 1.8em;
  padding-right: 10px;
  -webkit-text-stroke-width: 0.7px;
  -webkit-text-stroke-color: #646464;
  -webkit-font-smoothing: antialiased;
  color: #646464;
}
.altai-theme-content #bodyView .card__event-data {
  margin-bottom: 1.625em;
}
.altai-theme-content #bodyView .event-data-item {
  padding: 0 10 0 0;
  font-family: 'Campton', 'Helvetica', 'Arial', sans-serif;
  color: #636363;
  font-variant-ligatures: common-ligatures;
  margin-top: 0;
  margin-bottom: 0;
  font-size: 0.675em;
  line-height: 2.6em;
  letter-spacing: 1px;
  font-weight: 400;
  font-style: italic;
}
.altai-theme-content #bodyView .event-data-item .icon {
  font-size: 1.8em;
  padding-right: 10px;
  vertical-align: middle;
  -webkit-text-stroke-width: 0.7px;
  -webkit-text-stroke-color: #646464;
  -webkit-font-smoothing: antialiased;
  color: #646464;
}
.altai-theme-content #bodyView .menu > li a {
  text-decoration: none;
}
.altai-theme-content #bodyView .nav-bar.nav--absolute {
  border-bottom: 0 solid rgba(255, 255, 255, 0.1);
}
.altai-theme-content #bodyView .nav-bar {
  box-shadow: 0 0 0 rgba(0, 0, 0, 0), 0 0 0 rgba(0, 0, 0, 0);
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
}
.altai-theme-content #bodyView .slider.height-100 .slides li {
  /*height: 45vh;*/
  height: 600px !important;
  padding: 0;
}
.altai-theme-content #bodyView .myComeos {
  background-color: rgba(255, 0, 70, 0.7);
  width: 94px;
  height: 94px;
  padding: 0 0 0 0;
  border-radius: 50%;
  margin-left: 20px;
}
.altai-theme-content #bodyView .nav-bar.nav--top {
  padding: 12px;
  height: 100px;
  max-height: 100px;
  line-height: 98px;
}
@media (min-width: 991px) {
  .altai-theme-content #bodyView .nav-bar.nav--transparent:not(.nav--scrolled):not(.nav--fixed) .menu.menu--language > li > a {
    color: #ffc300 !important;
  }
}
.altai-theme-content #bodyView .nav-module.right:first-of-type {
  padding-right: 0;
  padding-left: 30px;
}
.altai-theme-content #bodyView .nav-module.right:last-of-type {
  padding-left: 0;
  padding-right: 30px;
}
.altai-theme-content #bodyView .slider h1 {
  margin-bottom: 0.2em;
}
.altai-theme-content #bodyView .lead {
  width: 60%;
  font-style: italic;
  margin-left: auto;
  margin-right: auto;
  line-height: 1.1em;
  letter-spacing: 0.5px;
}
.altai-theme-content #bodyView .button-row--centralized {
  text-align: center;
}
.altai-theme-content #bodyView .btn--discrete {
  background-color: transparent;
  border-color: transparent;
}
.altai-theme-content #bodyView .btn .btn__text,
.altai-theme-content #bodyView .btn i {
  font-size: 0.725em;
  /*adjusted button text size*/
}
@media (max-width: 990px) {
  .altai-theme-content #bodyView .nav-bar.nav--top,
  .altai-theme-content #bodyView .nav-bar.nav--fixed {
    display: none;
  }
}
.altai-theme-content #bodyView .logo-container-top {
  width: 250px;
  margin-left: 50px;
}
.altai-theme-content #bodyView .hidden {
  display: none;
}
.altai-theme-content #bodyView .nav--scrolled .mycomeos-label {
  width: 45px;
  height: 45px;
  font-family: 'Campton', 'Helvetica', 'Arial', sans-serif;
  background-color: rgba(255, 0, 70, 0.7);
  border-radius: 0;
  color: white;
  z-index: auto;
  position: inherit;
  float: none;
  right: 0;
  top: 0;
  padding: 0 0 0 0;
  text-align: center;
}
.altai-theme-content #bodyView .nav--scrolled .logo {
  height: 60px;
}
.altai-theme-content #bodyView .nav-bar .nav-module > a:not(.btn) {
  height: 100px;
}
.altai-theme-content #bodyView section,
.altai-theme-content #bodyView section.section-comeos,
.altai-theme-content #bodyView footer {
  padding-top: 4em;
  padding-bottom: 4em;
}
.altai-theme-content #bodyView .picheader {
  padding: 0 !important;
  overflow: hidden;
  position: relative;
  height: auto;
}
.altai-theme-content #bodyView .picheader .pic {
  height: 600px;
}
.altai-theme-content #bodyView .pagecontentstart {
  padding-top: 2.25em;
}
.altai-theme-content #bodyView .buttonrow {
  padding-top: 3em;
  padding-bottom: 3em;
}
.altai-theme-content #bodyView a {
  text-decoration: none;
  color: #000;
}
.altai-theme-content #bodyView .article-introduction {
  font-weight: 800;
  font-size: 1em;
}
.altai-theme-content #bodyView table {
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  border: 0;
}
.altai-theme-content #bodyView table caption {
  padding-top: 8px;
  padding-bottom: 8px;
  color: #777777;
  font-weight: 400;
  text-align: center;
  font-family: Campton;
  font-size: 0.7em;
}
.altai-theme-content #bodyView table th {
  background-color: #3e3e3e;
  padding: 10px 20px 10px 20px;
  color: #ececec;
  font-weight: 400;
  text-align: left;
  font-family: Campton;
  font-size: 0.8em;
  border: 0 solid white;
}
.altai-theme-content #bodyView table td {
  padding: 10px 20px 10px 20px;
  border: 1px solid #e0e0e0;
  font-size: 0.9em;
  line-height: 1.6em;
  text-align: left;
}
.altai-theme-content #bodyView table td.tablerowheader {
  width: 10%;
}
.altai-theme-content #bodyView table tr:nth-child(even) {
  background: #efefef;
}
.altai-theme-content #bodyView table tr:nth-child(even) td.tablerowheader {
  background-color: #e4e4e4;
}
.altai-theme-content #bodyView table tr:nth-child(odd) {
  background: #fcfcfc;
}
.altai-theme-content #bodyView table tr:nth-child(odd) td.tablerowheader {
  background-color: #efefef;
}
.altai-theme-content #bodyView .container-content span a,
.altai-theme-content #bodyView .container-content p a,
.altai-theme-content #bodyView .container-content table a,
.altai-theme-content #bodyView .container-content ul a,
.altai-theme-content #bodyView .container-content ol a,
.altai-theme-content #bodyView .container-content .cktext a {
  text-decoration: none;
  border-bottom: 4px solid #b6e1ff;
}
.altai-theme-content #bodyView .container-content span a:hover,
.altai-theme-content #bodyView .container-content p a:hover,
.altai-theme-content #bodyView .container-content table a:hover,
.altai-theme-content #bodyView .container-content ul a:hover,
.altai-theme-content #bodyView .container-content ol a:hover,
.altai-theme-content #bodyView .container-content .cktext a:hover {
  text-decoration: none;
  background-color: #b6e1ff;
}
.altai-theme-content #bodyView .container-content span .dossier-tags-chip a {
  text-decoration: none;
  border-bottom: none;
}
.altai-theme-content #bodyView .container-content span .dossier-tags-chip a:hover {
  text-decoration: none;
  background-color: inherit;
}
.altai-theme-content #bodyView .container-content .comeos-textbanner-green span a,
.altai-theme-content #bodyView .container-content .comeos-highlighted-green span a,
.altai-theme-content #bodyView .container-content .comeos-textbanner-green p a,
.altai-theme-content #bodyView .container-content .comeos-highlighted-green p a,
.altai-theme-content #bodyView .container-content .comeos-textbanner-green table a,
.altai-theme-content #bodyView .container-content .comeos-highlighted-green table a,
.altai-theme-content #bodyView .container-content .comeos-textbanner-green ul a,
.altai-theme-content #bodyView .container-content .comeos-highlighted-green ul a,
.altai-theme-content #bodyView .container-content .comeos-textbanner-green ol a,
.altai-theme-content #bodyView .container-content .comeos-highlighted-green ol a {
  text-decoration: none;
  border-bottom: 4px solid #008676;
}
.altai-theme-content #bodyView .container-content .comeos-textbanner-green span a:hover,
.altai-theme-content #bodyView .container-content .comeos-highlighted-green span a:hover,
.altai-theme-content #bodyView .container-content .comeos-textbanner-green p a:hover,
.altai-theme-content #bodyView .container-content .comeos-highlighted-green p a:hover,
.altai-theme-content #bodyView .container-content .comeos-textbanner-green table a:hover,
.altai-theme-content #bodyView .container-content .comeos-highlighted-green table a:hover,
.altai-theme-content #bodyView .container-content .comeos-textbanner-green ul a:hover,
.altai-theme-content #bodyView .container-content .comeos-highlighted-green ul a:hover,
.altai-theme-content #bodyView .container-content .comeos-textbanner-green ol a:hover,
.altai-theme-content #bodyView .container-content .comeos-highlighted-green ol a:hover {
  text-decoration: none;
  background-color: #008676;
}
.altai-theme-content #bodyView .container-content .btn span a,
.altai-theme-content #bodyView .container-content .btn p a,
.altai-theme-content #bodyView .container-content .btn table a,
.altai-theme-content #bodyView .container-content .btn ul a,
.altai-theme-content #bodyView .container-content .btn ol a {
  border-bottom: none;
}
.altai-theme-content #bodyView .container-content .btn span a:hover,
.altai-theme-content #bodyView .container-content .btn p a:hover,
.altai-theme-content #bodyView .container-content .btn table a:hover,
.altai-theme-content #bodyView .container-content .btn ul a:hover,
.altai-theme-content #bodyView .container-content .btn ol a:hover {
  background-color: inherit;
}
.altai-theme-content #bodyView .container-content ul:not(.menu):not(.slides):not(.accordion):not(.tabs):not(.tabs-content):not(.dropdown-menu),
.altai-theme-content #bodyView .container-content ol:not(.menu):not(.slides):not(.accordion):not(.tabs):not(.tabs-content) {
  line-height: 1.8em;
  font-size: 1em;
  text-align: justify;
  list-style-position: outside;
}
.altai-theme-content #bodyView .container-content ul {
  width: 95%;
  list-style-type: circle;
  -webkit-margin-before: 1em;
  -webkit-margin-after: 1em;
  -webkit-margin-start: 0px;
  -webkit-margin-end: 0px;
  -webkit-padding-start: 40px;
}
.altai-theme-content #bodyView .container-content ol {
  width: 95%;
  list-style-type: decimal;
  -webkit-margin-before: 1em;
  -webkit-margin-after: 1em;
  -webkit-margin-start: 0px;
  -webkit-margin-end: 0px;
  -webkit-padding-start: 40px;
}
.altai-theme-content #bodyView .container-content ul li {
  list-style: disc;
}
.altai-theme-content #bodyView .container-content ul li li {
  list-style: circle;
}
.altai-theme-content #bodyView .container-content ul li li li {
  list-style: square;
}
.altai-theme-content #bodyView .container-content table p {
  line-height: 1.6em;
  text-align: left;
  margin-top: 0.2em;
  margin-bottom: 0.2em;
}
.altai-theme-content #bodyView .container-content li p {
  line-height: 1.6em;
  text-align: justify;
  margin-top: 0em;
  margin-bottom: 0.625em;
}
.altai-theme-content #bodyView nav {
  margin-top: 44px;
}
.altai-theme-content #bodyView .card__image {
  min-height: 75px;
}
.altai-theme-content #bodyView .daterange table {
  padding: 20px 0 20px 0;
  margin: 0 !important;
  padding: 0 !important;
}
.altai-theme-content #bodyView .daterange table tr td {
  padding: 0 !important;
}
.altai-theme-content #bodyView .daterange .rangebutton {
  font-family: 'Campton', 'Helvetica', 'Arial', sans-serif;
  width: 120px;
  height: 25px;
  background: none;
  color: #767676;
  display: inline-block;
  font-weight: 600;
  text-align: center;
  margin-bottom: 15px;
}
.altai-theme-content #bodyView .daterange .rangebutton:hover {
  font-weight: 800;
}
.altai-theme-content #bodyView .daterange .range {
  display: inline-block;
  text-align: center;
  margin: 5px 0 5px 0;
  width: 100%;
  background: #FFF;
  border-color: #FFF;
  color: #767676;
  font-family: 'Campton', 'Helvetica', 'Arial', sans-serif;
}
.altai-theme-content #bodyView .daterange .range:hover {
  font-weight: 800;
}
.altai-theme-content #bodyView .daterange .su-datepicker {
  min-height: 170px;
  background-color: #FFF;
  font-size: 90%;
  font-family: 'Campton', 'Helvetica', 'Arial', sans-serif;
  margin-left: 0px;
  padding: 10px;
  position: relative;
}
.altai-theme-content #bodyView .daterange .su-datepicker-default,
.altai-theme-content #bodyView .daterange .su-datepicker-range {
  width: 265px;
}
.altai-theme-content #bodyView .daterange .su-datepicker-range > div:nth-of-type(1) {
  margin-bottom: 10px;
}
@media (min-width: 768px) {
  .altai-theme-content #bodyView .daterange .su-datepicker-range {
    width: 530px;
  }
  .altai-theme-content #bodyView .daterange .su-datepicker-range > div {
    float: left;
    width: 50%;
  }
  .altai-theme-content #bodyView .daterange .su-datepicker-range > div:nth-of-type(1) {
    margin-bottom: 0;
  }
  .altai-theme-content #bodyView .daterange .su-datepicker-range > div:nth-of-type(2) {
    padding-left: 10px;
  }
  .altai-theme-content #bodyView .daterange .su-datepicker-range:after {
    display: table;
    clear: both;
    content: " ";
  }
}
.altai-theme-content #bodyView .daterange .su-datepicker-default .su-datepicker-header,
.altai-theme-content #bodyView .daterange .su-datepicker-range .su-datepicker-header {
  color: #767676;
  font-size: 100%;
  padding-bottom: 5px;
  text-align: center;
}
.altai-theme-content #bodyView .daterange .su-datepicker-default table,
.altai-theme-content #bodyView .daterange .su-datepicker-range table {
  border-collapse: collapse;
  border-spacing: 0;
}
.altai-theme-content #bodyView .daterange .su-datepicker button {
  border: 0;
  margin: 0;
  padding: 0;
  height: inherit;
  color: #000;
}
.altai-theme-content #bodyView .daterange .su-datepicker-default table th,
.altai-theme-content #bodyView .daterange .su-datepicker-range table th {
  margin: 0;
  padding: 0;
  color: #767676;
  background: none !important;
  font-size: 85%;
}
.altai-theme-content #bodyView .daterange .su-datepicker-default td,
.altai-theme-content #bodyView .daterange .su-datepicker-range td {
  padding: 0;
  margin: 0;
  background: #F2F2F2;
}
.altai-theme-content #bodyView .daterange .su-datepicker-day {
  background-color: #F3F3F3;
  border-color: transparent;
  border-style: inset;
  cursor: pointer;
  font-size: inherit !important;
  font-weight: normal;
  padding: 9px;
  transition: all 0.125s ease-in-out;
}
.altai-theme-content #bodyView .daterange .su-datepicker-default .su-datepicker-day:active,
.altai-theme-content #bodyView .daterange .su-datepicker-default .su-datepicker-day:focus,
.altai-theme-content #bodyView .daterange .su-datepicker-range .su-datepicker-day:active,
.altai-theme-content #bodyView .daterange .su-datepicker-range .su-datepicker-day:focus,
.altai-theme-content #bodyView .daterange .su-datepicker-default .su-datepicker-change-button button:active,
.altai-theme-content #bodyView .daterange .su-datepicker-default .su-datepicker-change-button button:focus,
.altai-theme-content #bodyView .daterange .su-datepicker-range .su-datepicker-change-button button:active,
.altai-theme-content #bodyView .daterange .su-datepicker-range .su-datepicker-change-button button:focus {
  outline: none;
}
.altai-theme-content #bodyView .daterange .su-datepicker-default button[disabled],
.altai-theme-content #bodyView .daterange .su-datepicker-range button[disabled] {
  background: #fff;
  color: #999;
  cursor: not-allowed;
  opacity: .65;
  pointer-events: none;
}
.altai-theme-content #bodyView .daterange .su-datepicker-default .su-datepicker-change-button,
.altai-theme-content #bodyView .daterange .su-datepicker-range .su-datepicker-change-button {
  padding: 0 2px 5px 2px;
  position: absolute;
  top: 40%;
  width: 0;
}
.altai-theme-content #bodyView .daterange .su-datepicker-default .su-datepicker-change-button-previous,
.altai-theme-content #bodyView .daterange .su-datepicker-range .su-datepicker-change-button-previous {
  left: -28px;
}
.altai-theme-content #bodyView .daterange .su-datepicker-default .su-datepicker-change-button-next,
.altai-theme-content #bodyView .daterange .su-datepicker-range .su-datepicker-change-button-next {
  right: 8px;
}
.altai-theme-content #bodyView .daterange .su-datepicker-default .su-datepicker-change-button button,
.altai-theme-content #bodyView .daterange .su-datepicker-range .su-datepicker-change-button button {
  background: #fff;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 4px !important;
  color: #767676;
  font-size: 1.5em;
  height: 35px;
  line-height: 0;
  padding: 5px;
  transition: all 0.125s ease-in-out;
  width: 35px;
}
.altai-theme-content #bodyView .daterange .su-datepicker-default .su-datepicker-change-button .su-prev-arrow,
.altai-theme-content #bodyView .daterange .su-datepicker-range .su-datepicker-change-button .su-prev-arrow,
.altai-theme-content #bodyView .daterange .su-datepicker-default .su-datepicker-change-button .su-next-arrow,
.altai-theme-content #bodyView .daterange .su-datepicker-range .su-datepicker-change-button .su-next-arrow {
  fill: rgba(0, 0, 0, 0.5);
}
.altai-theme-content #bodyView .daterange .su-datepicker-default .su-datepicker-change-button button[disabled] .su-prev-arrow,
.altai-theme-content #bodyView .daterange .su-datepicker-range .su-datepicker-change-button button[disabled] .su-prev-arrow,
.altai-theme-content #bodyView .daterange .su-datepicker-default .su-datepicker-change-button button[disabled] .su-next-arrow,
.altai-theme-content #bodyView .daterange .su-datepicker-range .su-datepicker-change-button button[disabled] .su-next-arrow {
  fill: #ddd;
}
.altai-theme-content #bodyView .daterange .su-datepicker-default .su-datepicker-change-button button:hover:enabled,
.altai-theme-content #bodyView .daterange .su-datepicker-range .su-datepicker-change-button button:hover:enabled {
  background: #FAFAFA;
  border-color: #767676 !important;
  color: #6cb100;
}
.altai-theme-content #bodyView .daterange .su-datepicker-default .su-datepicker-change-button button:active,
.altai-theme-content #bodyView .daterange .su-datepicker-range .su-datepicker-change-button button:active,
.altai-theme-content #bodyView .daterange .su-datepicker-default .su-datepicker-change-button button:focus,
.altai-theme-content #bodyView .daterange .su-datepicker-range .su-datepicker-change-button button:focus {
  background: #fff;
  border-color: #767676 !important;
  color: #6cb100;
}
.altai-theme-content #bodyView .daterange .su-datepicker-default .su-datepicker-change-button button[disabled],
.altai-theme-content #bodyView .daterange .su-datepicker-range .su-datepicker-change-button button[disabled] {
  background: #fff;
  border-color: #ddd;
  color: #ddd;
  cursor: not-allowed;
  opacity: 1;
  pointer-events: none;
}
.altai-theme-content #bodyView .daterange .su-datepicker-day:hover,
.altai-theme-content #bodyView .daterange .potential-range {
  background-color: #767676;
  color: #FFF !important;
}
.altai-theme-content #bodyView .daterange .selected-range,
.altai-theme-content #bodyView .daterange .start-date,
.altai-theme-content #bodyView .daterange .start-date:hover,
.altai-theme-content #bodyView .daterange .end-date,
.altai-theme-content #bodyView .daterange .end-date:hover {
  background-color: #505050;
  color: #fff !important;
}
.altai-theme-content #bodyView ul.exposed-collection-dropdown {
  padding: 0;
  width: 302px;
  overflow: hidden;
}
.altai-theme-content #bodyView .exposed-collection-dropdown ul {
  padding: 0;
}
.altai-theme-content #bodyView ul.exposed-collection-dropdown li {
  list-style-type: none;
}
.altai-theme-content #bodyView .aggregationrow {
  padding: 20px 0 20px 0;
}
.altai-theme-content #bodyView .aggregationcheckbox {
  margin-right: 13px;
  width: 25px;
  position: relative;
}
.altai-theme-content #bodyView .aggregationcheckbox input[type=checkbox]:checked + label:after {
  opacity: 1;
}
.altai-theme-content #bodyView .aggregationcheckbox .checkboxlabel {
  margin-left: 20px;
  padding: 10px 0px;
  display: inline-block;
}
.altai-theme-content #bodyView .aggregationcheckbox label {
  cursor: pointer;
  position: absolute;
  width: 25px;
  height: 25px;
  top: 0;
  left: 0;
  background: #eee;
  border: 1px solid #ddd;
}
.altai-theme-content #bodyView .aggregationcheckbox label:after {
  opacity: 0.04;
  content: '';
  position: absolute;
  width: 9px;
  height: 5px;
  background: transparent;
  top: 6px;
  left: 7px;
  border: 3px solid #333;
  border-top: none;
  border-right: none;
  transform: rotate(-45deg);
}
.altai-theme-content #bodyView .aggregationcheckbox label:hover::after {
  opacity: 0.5;
}
.daterangepicker {
  display: none;
  background: #F3F3F3;
}
.daterangepicker .ranges {
  width: 100%;
}
.daterangepicker .ranges ul {
  width: 100%;
}
.daterangepicker .ranges li {
  background: #FFF;
  border-color: #FFF;
  color: #767676;
  font-family: 'Campton', 'Helvetica', 'Arial', sans-serif;
}
.daterangepicker .ranges li:hover {
  background: #767676;
  border-color: #767676;
  color: #FFF;
}
.daterangepicker .ranges li.active {
  background: #767676 !important;
  border-color: #767676;
  color: #FFF;
}
.daterangepicker .calendar {
  max-width: 50% !important;
  width: calc(50% - 8px);
}
.daterangepicker .calendar .daterangepicker_input {
  max-width: 100%;
}
.daterangepicker .calendar.right {
  float: right !important;
}
